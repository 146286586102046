import Image from "next/image";
import styles from "../../styles/finance.module.css";
import useIsMobile from "../../hooks/useIsMobile";
import useWindowSize from "../../hooks/useWindowSize";
import { configuration } from "../../helpers/config";
import ReactPlayer from "react-player";
import React,{useState} from "react";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";

// Banner component for static pages.
const FinanceBanner = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMobile, setIsLoadingMobile] = useState(true);
  const [hideImage, setHideImage] = useState(false);
  const mobile = useIsMobile();
  const windoWidth = useWindowSize();
  return (
    <>
      <div
        className={` ${styles.banner_image}`}
        style={{ marginBottom: `${props.bannerClass}`}}
      >
       <div className={styles.banner}>
          {mobile ? (
            props.videoMob ? (
              <ReactPlayer
                url={props.videoMob}
                playing={true}
                volume={0}
                height="100%"
                width="100%"
                loop={true}
                playsinline
              />
            ) : (
              <>
               {/* {isLoadingMobile && !hideImage && <Skeleton height={1080} width={768} />} */}
               {!hideImage && (
              <Image
                src={props.imageMob}
                height={!isLoadingMobile?1080:0}
                width={!isLoadingMobile?768:0}
                alt={props.alt_image}
                className={`${styles.bannerImage} ${isLoadingMobile ? "hidden" : "block"}`}
                onLoad={() => setIsLoadingMobile(false)}
                onError={() => setHideImage(true)}
              />
               )}
              </>
            )
          ) : props.video ? (
            <ReactPlayer
              url={props.video}
              playing={true}
              volume={0}
              height="100%"
              width="100%"
              loop={true}
              playsinline
            />
          ) : (
            <>
            {/* {isLoading && !hideImage && <Skeleton containerClassName="flex-1" height={1004} width="100%" />} */}
            {!hideImage && (
            <Image
              src={props.image}
              height={!isLoading?1004:0}
              width={!isLoading?1928:0}
              alt={props.alt_image}
              className={`${styles.bannerImage} ${isLoading ? "hidden" : "block"}`}
              onLoad={() => setIsLoading(false)}
              onError={() => setHideImage(true)}
            />
            )}
            </>
          )}
        </div>

        <div className={styles.bannerConts}>
        <div className="banner-content container">
            {props.isTitlePresent && props.isTitleVisible ?
              <h3
            className={`${styles.banner_content_head} ${props.head == "e VITARA" ? styles.no_uppercase : ""}`}
              style={{
                color: props.titleThemeData?.titleColor,
                fontSize:
                  windoWidth.width > 1024
                    ? props.titleThemeData?.titleFontSize
                    : "",
              }}
            >
              {props.head}
            </h3> :
            <h1
            className={`${styles.banner_content_head} ${props.head == "e VITARA" ? styles.no_uppercase : ""}`}
              style={{
                color: props.titleThemeData?.titleColor,
                fontSize:
                  windoWidth.width > 1024
                    ? props.titleThemeData?.titleFontSize
                    : "",
              }}
            >
              {props.head}
            </h1>}
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceBanner;
